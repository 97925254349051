<template>
    <Modal
        ref="modal"
        :animated="true"
        :remove-main-slot-content-when-invisible="true"
        event-type="MODAL_SELECT_TENANT"
        @close="onClose"
        @show="onShow"
    >
        <div class="content-wrapper">
            <h2 class="title">
                {{ trans('modals.select_tenant.title') }}
            </h2>

            <SearchInput
                :filter-words="searchTerm"
                autofocus
                @cancel="() => onSearchChanged(null)"
                @change="onSearchChanged"
            />

            <LoadingIndicator v-if="tenantService.isLoading" />

            <ul class="tenant-list">
                <TenantListItem
                    v-for="tenant in tenants"
                    :key="tenant.uid"
                    :should-show-tenant-uid="shouldShowTenantUid"
                    :tenant="tenant"
                    @select="onTenantSelecetd"
                />
            </ul>
        </div>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {trans} from '@/Utility/Helpers/trans';
import {tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import SearchInput from '@/Vue/Common/SearchInput.vue';
import Search from '@/Utility/Search';
import type Tenant from '@/Models/Tenant/Tenant';
import EventType from '@/Utility/EventType';
import TenantListItem from '@/Vue/Modals/SelectTenant/TenantListItem.vue';
import LoadingIndicator from '@/Vue/Common/LoadingIndicator.vue';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({

    components: {
        LoadingIndicator,
        TenantListItem,
        SearchInput,
        Modal,
    },

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,
            searchTerm: null as string | null,
        };
    },

    computed: {
        tenants(): Tenant[] {
            if (!this.searchTerm) {
                return this.tenantService.tenants;
            }

            const properties = ['name'];

            if (this.shouldShowTenantUid) {
                properties.push('uid');
            }

            return Search.filterObjects(this.tenantService.tenants, properties, this.searchTerm!);
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },

        shouldShowTenantUid() {
            return this.$gate.allows(Permission.TenantsDisplayUid());
        },
    },
    methods: {
        trans,

        onSearchChanged(searchTerm: string | null) {
            this.searchTerm = searchTerm;
        },

        onTenantSelecetd(tenant: Tenant) {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_SELECT_TENANT_APPLY, tenant);
        },

        onShow() {
            if (this.tenantService.tenants.length > 0) {
                return;
            }

            this.tenantService
                .fetchTenants()
                .catch(this.onApiError);
        },

        onClose() {
            this.searchTerm = null;
        },

        onApiError(error: string | Error) {
            this.modal.hide();
            this.$root!.showErrorDialog(error);
        },
    },
});
</script>

<style lang="scss" scoped>

dialog {
    width: 440px;

    .content-wrapper {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto min(450px, 60vh);

        .search-input {
            :deep(.textinput) {
                flex-grow: 3;

                input:not(:focus, :focus-visible, :active) {
                    border-color: var(--color-anthracite10);
                }
            }
        }

        .loading-indicator {
            justify-self: center;
            align-self: center;
        }

        .tenant-list {
            list-style-type: none;
            margin: 16px -32px 8px;
            overflow-y: auto;
        }
    }
}

</style>
