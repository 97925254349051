<template>
    <ModalApplyCancel
        ref="modal"
        :description="description"
        :title="trans('modals.delete_identity_provider.title')"
        event-type="MODAL_DELETE_IDENTITY_PROVIDER"
        @show="onShow"
    />
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import {defineComponent} from 'vue';
import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
import type IdentityProvider from '@/Models/IdentityProviders/IdentityProvider';

export default defineComponent({
    components: {
        ModalApplyCancel,
    },

    data() {
        return {
            description: null as string | null,
        };
    },

    methods: {
        trans,

        onShow(provider: IdentityProvider) {
            this.description = trans('modals.delete_identity_provider.description', {
                name: provider.name,
                domain: provider.domain,
            });
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
