<template>
    <a
        :href="route('identity_providers.show', { identity_provider: provider.uid })"
        class="identity-provider-logo"
    >
        <img
            :alt="provider.driver.name"
            :src="provider.driver.logo"
            :title="provider.name"
        >
    </a>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import IdentityProviderBasics from '@/Models/IdentityProviders/IdentityProviderBasics';
import {route} from '@/Utility/Helpers';

export default defineComponent({
    props: {
        provider: {
            type: IdentityProviderBasics,
            required: true,
        },
    },

    methods: {
        route
    },
});
</script>

<style lang="css" scoped>

a {
    display: inline-block;
}
img {
    width: 100%;
}

</style>
