<template>

    <div
        v-if="canDeleteAnyToken"
        :data-saving="isSaving"
        class="container"
    >

        <div class="row justify-content-left">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">{{ trans('tokens.logout_form.headline') }}</div>

                    <div class="card-body">
                        <p>{{ trans('tokens.logout_form.description') }}</p>
                        <div class="buttons">
                            <ButtonPrimary
                                :caption="trans('tokens.logout_form.button_logout')"
                                :disabled="!canDeleteAnyToken"
                                class="btn-red"
                                @trigger="onLogoutButtonTriggered"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalApplyCancel
            :description="trans('tokens.dialogs.confirm_logout.description')"
            :title="trans('tokens.dialogs.confirm_logout.headline')"
            event-type="MODAL_APPLY_CANCEL"
        />

    </div>
</template>

<script>

// Import VueJS components:
import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';

// Import classes:
import {permission, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import EventType from '@/Utility/EventType';
import {inject} from 'vue';
import {tokenServiceKey} from '@/Vue/Bootstrap/InjectionKeys';

export default {
        name: 'LogoutUsersForm',

        data() {
            return {
                tokenService: inject(tokenServiceKey),
            }
        },

        mounted() {
            this.$globalEvents.on(EventType.MODAL_APPLY_CANCEL_APPLY, this.onLogoutConfirmed);
        },

        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_APPLY_CANCEL_APPLY, this.onLogoutConfirmed);
        },

        components: {
            ModalApplyCancel,
            ButtonPrimary,
        },

        computed: {

            canDeleteAnyToken() {
                //console.log("can delete any: " + permission(Permission.TokensDeleteAny()));
                return permission(Permission.TokensDeleteAny());
            },

            /**
             * Saving state
             *
             * @returns {Boolean}
             */
            isSaving() {
                if (this.tokenService.isSaving) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            }
        },

        methods: {
            trans,

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                this.$root.showErrorDialog(error);
                return this;
            },

            onLogoutSuccess() {
                this.$toast.success(trans('tokens.dialogs.logout_success'));
                return this;
            },

            onLogoutButtonTriggered() {
                this.$globalEvents.emit(EventType.MODAL_APPLY_CANCEL_SHOW);
                return this;
            },

            onLogoutConfirmed() {
                this.tokenService
                    .revokeAllLoginTokens()
                    .then(this.onLogoutSuccess)
                    .catch(this.onErrorApi);
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
