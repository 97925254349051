<template>
    <section :key="'user-list-item-'+key" class="user-list-item">
        <div class="user-list-item-preview">
            <div class="user-avatar" :data-initials="user!.initials"></div>
        </div>
        <main class="user-list-item-content">
            <div class="user-info">
                <h3 class="user-info-name">{{ user.fullName }}</h3>
                <p class="user-info-email">{{ user.email }}</p>
            </div>
        </main>
        <footer>
            <div class="user-list-item-labels">
                <StatusLabel
                    v-for="labelConfig in labels"
                    :key="'statuslabel'+user.uid+(labelConfig.type || labelConfig.caption)"
                    :config="labelConfig"
                />
            </div>

            <template v-if="hasActions">
                <div class="user-list-item-actions">

                    <!-- Btn: Edit -->
                    <ButtonCircular
                        v-if="isEditable"
                        v-tooltip="'buttons.users.edit'"
                        :href="route('users.updateForm', {'user': user.uid})"
                        icon="icon_edit"
                    />
                    <ButtonCircular
                        v-else
                        :disabled="true"
                        v-tooltip="'buttons.users.edit_off'"
                        :href="route('users.updateForm', {'user': user.uid})"
                        icon="icon_edit_off"
                    />

                    <!-- Btn: Delete -->
                    <ButtonCircular
                        v-if=showRemoveButton
                        :disabled="!isRemovable"
                        v-tooltip="'buttons.users.remove'"
                        icon="icon_delete"
                        @trigger="$emit('click-remove', user)"
                    />
                    <ButtonCircular
                        v-if="showDeleteButton"
                        :disabled="!isDeletable"
                        v-tooltip="'buttons.users.delete'"
                        icon="icon_delete"
                        @trigger="$emit('click-delete', user)"
                    />
                </div>
                <Icon class="icon-more" name="icon_more_horizontal" />
            </template>
        </footer>
    </section>
</template>

<script lang="ts">

import {permission, route, shortId, trans} from '@/Utility/Helpers';
import type { PropType} from 'vue';
import {defineComponent} from 'vue';
import type Tenant from '@/Models/Tenant/Tenant';
import type User from '@/Models/User/User';
import {UserRole} from '@/Models/User/UserRole';
import {Permission} from '@/Models/User/Permission';
import Icon from '@/Vue/Common/Icon.vue';
import StatusLabel from '@/Vue/Common/StatusLabel.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import StatusLabelConfig from '@/Utility/StatusLabelConfig';

export default defineComponent({
    components: {
        ButtonCircular, StatusLabel,
        Icon
    },

    props: {
        user: {
            type: Object as PropType<User>,
            required: true,
        },
        tenant: {
            type: Object as PropType<Tenant>,
            required: true,
        },
    },

    emits: {
        'click-remove': (_: User) => true,
        'click-delete': (_: User) => true,
    },

    data() {
        return {
            key: shortId('remove-user-form'),
            isSubmitting: false,
        };
    },

    computed: {

        hasActions(): boolean {
            return true;
        },

        showRemoveButton(): boolean {
            return (
                this.canRemoveUser && this.isRemovable
            );
        },

        showDeleteButton(): boolean
        {
            return this.canDeleteUser && !this.showRemoveButton;
        },

        isDeletable(): boolean {
            return this.$gate.allows(Permission.ability(Permission.UsersDelete()), this.user);
        },

        isRemovable(): boolean {
            return (
                this.$gate.allows(Permission.ability(Permission.TenantsRemoveUsers()), this.tenant, this.user)
                && this.user.belongsToMultipleTenants()
            );
        },

        canRemoveUser() {
            return permission(Permission.TenantsRemoveUsers());
        },

        canDeleteUser() {
            return (
                permission(Permission.UsersDelete())
                || permission(Permission.UsersDeleteAny())
            );
        },

        isAdminUser(): boolean {
            return this.user.role === UserRole.Admin || this.user.role === UserRole.SuperAdmin;
        },

        isLmsUser(): boolean {
            return this.user.role === UserRole.Lms;
        },

        isEditable(): boolean {
            return this.$gate.allows(Permission.ability(Permission.UsersUpdate()), this.user);
        },

        labels(): StatusLabelConfig[] {
            const labels: StatusLabelConfig[] = [];

            labels.push(
                new StatusLabelConfig({
                    type: 'draft',
                    caption: trans('tenant_roles.' + this.user!.tenant_role!.name),
                    icon: this.roleIcon,
                })
            );

            if (this.user.identity_provider) {
                labels.push(
                    new StatusLabelConfig({
                        type: 'draft',
                        caption: this.user.identity_provider.driver.name,
                        icon: this.user.identity_provider.driver.logo,
                    })
                );
            }

            return labels;
        },

        roleIcon(): string | null {
            if (this.isAdminUser) {
                return 'icon_admin';
            } else if (this.isLmsUser) {
                return 'icon_temp';
            } else {
                return null;
            }
        },
    },

    methods: {
        route,
        trans,
    }
});
</script>

<style lang="scss" scoped>

.user-avatar {
    width: 60px;
    height: 60px;

    &[data-initials]:before {
        color: var(--color-white);
        background-color: var(--color-anthracite80);
        font-family: var(--font-family-condensed-demibold);
        font-size: 20px;
        opacity: 1;
        content: attr(data-initials);
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 0.5em;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        text-transform: uppercase;
    }
}

.user-list-item {
    display: grid;
    grid-auto-flow: row;
    grid-column-gap: 12px;
    grid-row-gap: 10px;
    grid-template-columns: [preview] min(80px, 100px) [content] auto;
    grid-template-rows: [content] auto [footer] 40px;
    grid-template-areas:
            'preview-area content-area'
            'preview-area footer-area';

    min-width: 600px;

    background: var(--color-white);
    border-radius: var(--card-border-radius);
    overflow: hidden;
    padding: 8px 16px 8px 8px;
    transition: box-shadow 0.15s ease-in-out;

    &:first-child {
        margin-top: 15px; // To fix box-shadow being cut-off at the top
    }

    &.selected,
    &:focus,
    &:focus-within,
    &:hover {
        box-shadow: var(--card-box-shadow-hover);
    }

    .user-list-item-preview {
        position: relative;
        grid-area: preview-area;
        max-width: 150px;
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: var(--card-border-radius-small);
        overflow: hidden;
        display: grid;
        justify-items: center;
        align-items: center;
        color: white !important;
    }

    &.selected .user-list-item-preview,
    &:hover .user-list-itemt-preview,
    .user-list-item-preview:focus-visible {
        &:before, .icon {
            opacity: 1;
        }
    }

    .user-list-item-content {
        grid-area: content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 8px;
    }

    .user-info-name {
        display: block;
        margin-bottom: 4px;
        margin-top: 8px;
    }

    .user-info-email {
        display: block;
        max-width: 480px;
        line-height: var(--line-height-default);
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: var(--color-anthracite40);
    }

    footer {
        grid-area: footer-area;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 16px 0 8px 0;

        .user-list-item-labels {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        .icon-more {
            flex-basis: 12px;
            margin-right: 14px;
            width: 40px;
            height: 40px;
        }

        .user-list-item-actions {
            display: none;
            transition: opacity .1s ease;
        }
    }

    &:hover,
    &:focus,
    &:focus-within,
    &.selected {
        footer .icon-more {
            display: none;
        }

        footer .user-list-item-actions {
            display: flex;
        }
    }

    &.is-new.list-items-enter-active {
        animation: new-list-item-added .3s cubic-bezier(.36, -0.64, .34, 1.26) 0s 1 forwards;
    }

    &.list-items-leave-active {
        animation: list-item-removed .3s cubic-bezier(.36, -0.64, .34, 1.26) 0s 1 forwards;
    }
}
</style>
