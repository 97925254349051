import type {UpdateIdentityProviderParameters} from '@/Services/IdentityProviderService';
import {parseDate} from '@/Utility/Helpers';
import IdentityProviderBasics from '@/Models/IdentityProviders/IdentityProviderBasics';

export default class IdentityProvider extends IdentityProviderBasics {

    readonly domain: string;
    readonly client_id: string;
    readonly configuration: object | null;
    readonly user_provisioning_enabled: boolean;

    readonly created_at: Date;
    readonly updated_at: Date;

    constructor(attributes: any) {
        super(attributes);

        this.domain = attributes.domain;
        this.client_id = attributes.client_id;
        this.configuration = attributes.configuration;
        this.user_provisioning_enabled = attributes.user_provisioning_enabled;

        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
    }

    toUpdateIdentityProviderParameters(): UpdateIdentityProviderParameters {
        return {
            name: this.name,
            domain: this.domain,
            client_id: this.client_id,
            configuration: this.configuration,
        };
    }
}
