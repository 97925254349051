<template>

    <main id="layout-main">
        <PageHeader :page-title="trans('instance_management.index.headline')" />

        <div id="layout-content">
            <div id="content">

                <logout-users-form />

                <manage-local-features-form />

                <InstanceManagementPageLink
                    v-if="featureRepository.active(Feature.EntitlementAuthScim)"
                    :description="trans('identity_providers.index.description')"
                    :permission="Permission.IdentityProvidersIndex()"
                    :title="trans('identity_providers.index.headline')"
                    :url="route('identity_providers.index')"
                    icon="icon_identity-provider"
                />

                <InstanceManagementPageLink
                    :description="trans('instance_management.users.description')"
                    :permission="Permission.UsersReadInstanceWide()"
                    :title="trans('instance_management.users.headline')"
                    :url="route('manage.users.index')"
                    icon="icon_users"
                />

                <InstanceManagementPageLink
                    :description="trans('instance_management.features.description')"
                    :permission="Permission.InstanceManagementFeatures()"
                    :title="trans('instance_management.features.headline')"
                    :url="route('manage.features.index')"
                />

                <InstanceManagementPageLink
                    :description="trans('artisan_commands.commands_list_description')"
                    :permission="Permission.InstanceManagementCommands()"
                    :title="trans('artisan_commands.commands_list')"
                    :url="route('manage.commands.index')"
                />

                <InstanceManagementPageLink
                    :description="trans('instance_management.permissions.description')"
                    :permission="Permission.InstanceManagementPermissions()"
                    :title="trans('instance_management.permissions.headline')"
                    :url="route('manage.permissions.index')"
                />

                <InstanceManagementPageLink
                    :description="trans('instance_management.open_api.description')"
                    :permission="Permission.InstanceManagementOpenApi()"
                    :title="trans('instance_management.open_api.headline')"
                    :url="route('manage.open_api.view')"
                />

                <InstanceManagementPageLink
                    :description="trans('instance_management.translations.description')"
                    :permission="Permission.InstanceManagementTranslations()"
                    :title="trans('instance_management.translations.headline')"
                    :url="route('manage.translations.index')"
                />

            </div>

            <!-- Dialogs -->
            <ModalProgress />
            <ModalNotification />
        </div>

    </main>

</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import LogoutUsersForm from '@/Vue/Tokens/LogoutUsersForm.vue';
import ManageLocalFeaturesForm from '@/Vue/Features/Local/ManageLocalFeaturesForm.vue';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import InstanceManagementPageLink from '@/Vue/InstanceManagement/InstanceManagementPageLink.vue';
import {route, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import {Feature} from '@/Models/Features/Feature';
import {featureRepositoryKey} from '@/Vue/Bootstrap/InjectionKeys';

export default defineComponent({

    components: {
        InstanceManagementPageLink,
        ModalProgress,
        ModalNotification,
        ManageLocalFeaturesForm,
        LogoutUsersForm,
        PageHeader
    },

    data() {
        return {
            featureRepository: inject(featureRepositoryKey)!,
        };
    },

    computed: {
        Feature() {
            return Feature;
        },
        Permission() {
            return Permission;
        }
    },

    methods: {
        route,
        trans
    }
});
</script>

<style lang="css" scoped>

#content:deep(.row > * ) {
    padding: 0;
}

</style>
