import type {IdentityProviderDriverId} from '@/Models/IdentityProviders/IdentityProviderDriver';
import IdentityProviderDriverList from '@/Models/IdentityProviders/IdentityProviderDriver';

export default class IdentityProviderBasics {

    readonly uid: string;
    readonly name: string;
    readonly driver_id: IdentityProviderDriverId;

    constructor(attributes: any) {
        this.uid = attributes.uid;
        this.name = attributes.name;
        this.driver_id = attributes.driver_id;
    }

    get driver() {
        return IdentityProviderDriverList[this.driver_id];
    }
}
