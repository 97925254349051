<template>
    <main
        id="layout-main"
        :data-saving="isSaving"
    >
        <PageHeader
            :buttons="headerButtons"
            :page-title="identityProvider.name"
        />

        <div id="layout-content">
            <div id="content">
                <div class="identity-provider-content">

                    <p>{{ trans('identity_providers.show.authentication.description') }}</p>

                    <div class="statistics">
                        <StatisticsCardCount
                            :count="usersCount"
                            :is-loading="false"
                            :title="trans('labels.users')"
                            class="unit-count"
                            icon="icon_users"
                        />

                        <StatisticsCardCount
                            :count="groupsCount"
                            :is-loading="false"
                            :title="trans('labels.groups')"
                            class="unit-count"
                            icon="icon_tenants"
                        />
                    </div>

                    <h2>Settings</h2>
                    <div class="authentication">
                        <h3>{{ trans('identity_providers.show.authentication.headline') }}</h3>
                        <p>{{ trans('identity_providers.show.authentication.description') }}</p>
                        <ButtonSecondary
                            :caption="trans('identity_providers.show.authentication.button')"
                            :disabled="!canUpdate"
                            :href="route('identity_providers.edit', {identity_provider: identityProvider.uid})"
                        />
                    </div>

                    <div class="user-provisioning">
                        <h3>{{ trans('identity_providers.show.user_provisioning.headline') }}</h3>
                        <p>{{ trans('identity_providers.show.user_provisioning.description') }}</p>

                        <div
                            v-if="shouldShowScimEndpoint"
                            class="user-provisioning-scim-endpoint"
                        >
                            <TextInput
                                :initial-value="scimEndpoint"
                                :label="trans('labels.scim_endpoint')"
                                :maxlength="255"
                                :placeholder="trans('scim_providers.placeholders.name')"
                                :read-only="true"
                            />
                            <ButtonCircular
                                v-tooltip="'labels.copy'"
                                class="small"
                                icon="icon_copy"
                                @trigger="onSCIMEndpointCopy($event)"
                            />
                        </div>

                        <ButtonPrimary
                            v-if="shouldShowScimEndpoint"
                            :caption="trans('identity_providers.show.user_provisioning.delete_api_key')"
                            :disabled="!canUpdate"
                            class="btn-red"
                            @trigger.prevent="onDisableUserProvisioningClick"
                        />

                        <ButtonPrimary
                            v-if="!shouldShowScimEndpoint"
                            :caption="trans('identity_providers.show.user_provisioning.create_api_key')"
                            :disabled="!canUpdate"
                            @trigger.prevent="onEnableUserProvisioningClick"
                        />
                    </div>
                </div>
            </div>

            <!-- Modals go here -->
            <ModalRenameIdentityProvider />
            <ModalShowUserProvisioningApiKey />
            <ModalDeleteIdentityProvider />
            <ModalProgress />
            <ModalNotification />
        </div>

    </main>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import IdentityProvider from '@/Models/IdentityProviders/IdentityProvider';
import {permission, route, trans} from '@/Utility/Helpers';
import TextInput from '@/Vue/Common/TextInput.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import {identityProviderServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import type RequestError from '@/Errors/RequestError';
import EventType from '@/Utility/EventType';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ModalDeleteIdentityProvider from '@/Vue/Modals/ModalDeleteIdentityProvider.vue';
import ModalShowUserProvisioningApiKey from '@/Vue/Modals/ModalShowUserProvisioningApiKey.vue';
import PageHeaderButton from '@/Utility/PageHeaderButton';
import AuthorizationError from '@/Errors/AuthorizationError';
import {Permission} from '@/Models/User/Permission';
import StatisticsCardCount from '@/Vue/Statistics/General/Cards/StatisticsCardCount.vue';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import Clipboard from '@/Utility/Clipboard';
import ModalRenameIdentityProvider from '@/Vue/Modals/ModalRenameIdentityProvider.vue';
import type {UpdateIdentityProviderParameters} from '@/Services/IdentityProviderService';

export default defineComponent({
    components: {
        ModalRenameIdentityProvider,
        ButtonCircular,
        PageHeader,
        StatisticsCardCount,
        ModalDeleteIdentityProvider,
        ModalShowUserProvisioningApiKey,
        ButtonSecondary,
        ModalNotification,
        ModalProgress,
        ButtonPrimary,
        TextInput,
    },

    props: {
        identityProviderJson: {
            type: String,
            required: true,
        },
        usersCount: {
            type: Number,
            required: true,
        },
        groupsCount: {
            type: Number,
            required: true,
        },
    },

    data() {
        const identityProvider = new IdentityProvider(JSON.parse(this.identityProviderJson));
        const secretPlaceholder = 'xxxxxxxx';

        return {
            identityProviderService: inject(identityProviderServiceKey)!,
            identityProvider: identityProvider,
            form: identityProvider.toUpdateIdentityProviderParameters(),
            errors: {} as Record<string, string[]>,
            secretPlaceholder: secretPlaceholder,
            clientSecret: secretPlaceholder,

            apiKey: null as string | null,

            events: new Map([
                [EventType.MODAL_RENAME_IDENTITY_PROVIDER_APPLY, this.onRenameIdentityProviderApply],
                [EventType.MODAL_DELETE_IDENTITY_PROVIDER_APPLY, this.onDeleteProviderConfirmed],
                [EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav],
                [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],
            ]),
        };
    },

    computed: {
        formHtml() {
            return this.$refs.form as HTMLFormElement;
        },

        canUpdate() {
            return permission(Permission.IdentityProvidersUpdate());
        },

        canDelete() {
            return permission(Permission.IdentityProvidersDelete());
        },

        isSaving() {
            if (this.identityProviderService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        headerButtons() {
            return {
                renameProvider: new PageHeaderButton({
                    caption: trans('labels.rename'),
                    visible: this.canUpdate,
                    icon: 'icon_edit',
                    tooltip: 'buttons.identity_providers.rename',
                    callback: this.onRenameClick,
                }),
                delete: new PageHeaderButton({
                    caption: trans('labels.delete'),
                    visible: this.canDelete,
                    icon: 'icon_delete',
                    tooltip: 'buttons.identity_providers.delete',
                    callback: this.onDeleteClick,
                }),
            };
        },

        shouldShowScimEndpoint() {
            return this.identityProvider.user_provisioning_enabled || this.apiKey !== null;
        },

        scimEndpoint() {
            // "?aadOptscim062020" is required for entra id to make SCIM 2.0 compliant PATCH requests
            return route('api.scim.providers.endpoint', { identity_provider: this.identityProvider.uid }) + '?aadOptscim062020';
        },
    },

    mounted() {
        // Add global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });
    },

    beforeUnmount() {
        // Remove global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {
        route,
        trans,

        validationErrors(property: string): string[] {
            return this.errors[property] || [];
        },

        removeValidationErrors(property: string) {
            delete this.errors[property];
        },

        onSave() {
            if (!this.formHtml.reportValidity()) {
                return;
            }

            if (this.clientSecret !== this.secretPlaceholder) {
                this.form.client_secret = this.clientSecret;
            }

            this.identityProviderService
                .updateIdentityProvider(this.identityProvider.uid, this.form)
                .then(provider => {
                    this.identityProvider = provider;
                    this.form = provider.toUpdateIdentityProviderParameters();
                })
                .catch(this.onApiError);
        },

        onDeleteProviderConfirmed() {
            this.identityProviderService
                .deleteIdentityProvider(this.identityProvider.uid)
                .then(() => window.location.href = route('identity_providers.index'))
                .catch(this.onApiError);
        },

        onDeleteClick() {
            this.$globalEvents.emit(EventType.MODAL_DELETE_IDENTITY_PROVIDER_SHOW, this.identityProvider);
        },

        onEnableUserProvisioningClick() {
            this.identityProviderService
                .enableUserProvisioning(this.identityProvider.uid)
                .then((apiKey: string) => {
                    this.apiKey = apiKey;
                    this.$globalEvents.emit(EventType.MODAL_SHOW_USER_PROVISIONING_API_KEY_SHOW, apiKey);
                })
                .catch(this.onApiError);
        },

        onDisableUserProvisioningClick() {
            this.identityProviderService
                .removeUserProvisioning(this.identityProvider.uid)
                .then(() => {
                    this.apiKey = null;
                    const provider = new IdentityProvider({
                        uid: this.identityProvider.uid,
                        name: this.identityProvider.name,
                        domain: this.identityProvider.domain,
                        driver_id: this.identityProvider.driver_id,
                        client_id: this.identityProvider.client_id,
                        configuration: this.identityProvider.configuration,
                        user_provisioning_enabled: null,
                        created_at: this.identityProvider.created_at,
                        updated_at: this.identityProvider.updated_at,
                    });

                    this.identityProvider = provider;
                    this.form = provider.toUpdateIdentityProviderParameters();
                })
                .catch(this.onApiError);
        },

        async onRenameIdentityProviderApply(name: string) {
            if (name === this.identityProvider.name) {
                return;
            }

            return this.saveIdentityProvider({
                name: name,
            });
        },

        async saveIdentityProvider(updateParameters: UpdateIdentityProviderParameters) {
            return this.identityProviderService
                .updateIdentityProvider(this.identityProvider.uid, updateParameters)
                .then(identityProvider => {
                    this.identityProvider = identityProvider;
                })
                .catch(this.onErrorApi);
        },

        onRenameClick() {
            this.$globalEvents.emit(EventType.MODAL_RENAME_IDENTITY_PROVIDER_SHOW, this.identityProvider);
        },

        onSCIMEndpointCopy(e: Event) {
            (e.target as HTMLElement)?.blur();
            Clipboard.setClipboardStringAsync(this.scimEndpoint);
        },

        onApiError(error: RequestError | AuthorizationError) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
                return;
            }

            if (error.isValidationError) {
                this.errors = error.validationErrors;
                return;
            }

            this.$root!.showErrorDialog(error);
        },

        onBeforeUnload() {
            this.identityProviderService.cancelRequests();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },

        /**
         * Click handler for header navigation buttons that delegates the action to the button callback method
         */
        onClickHeaderNav(buttonConfig: PageHeaderButton) {
            if (buttonConfig.callback === null) {
                return this;
            }

            buttonConfig.callback.call(this, buttonConfig);
            return this;
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }
            this.$root?.showErrorDialog(error);
        },
    }
});
</script>

<style lang="css" scoped>

:deep(h1) {
    text-transform: capitalize;
}

#content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 50px 50px 50px;

    .identity-provider-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 12px 0 30px;
        max-width: var(--container-max-width);
        min-width: 670px;

        .statistics {
            display: flex;
            gap: 24px;
        }
    }

    .authentication {
        background-color: white;
        border-radius: var(--card-border-radius);
        padding: 30px 48px;
        width: 100%;

        .btn {
            margin-top: 24px;
        }
    }

    .user-provisioning {
        background-color: white;
        border-radius: var(--card-border-radius);
        padding: 30px 48px;
        width: 100%;

        .textinput {
            margin-top: 24px;
            flex-grow: 1;
            margin-bottom: 0;

            &:deep(label) {
                padding: 0 0 8px 0;
                font-family: var(--font-family-condensed-demibold);

                &:has(+ :required):after {
                    content: ' *';
                }
            }
        }

        .btn {
            margin-top: 24px;
        }

        .user-provisioning-scim-endpoint {
            display: flex;
            gap: 12px;

            .btn {
                align-self: end;
                margin-top: 0;
                margin-bottom: 4px;;
            }
        }
    }
}

</style>
