<template>
    <section v-if="hasPermission" class="container" @dblclick="onDoubleCLick">
        <main>
            <h3>
                <Icon v-if="icon" :name="icon" />
                {{ title }}
            </h3>
            <p v-if="description">
                {{ description }}
            </p>
        </main>
        <footer>
            <ButtonCircular
                :href="url"
                :title="title"
                icon="icon_arrow_right"
            />
        </footer>
    </section>
</template>

<script lang="ts">
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import {route} from '@/Utility/Helpers';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import Icon from '@/Vue/Common/Icon.vue';

export default defineComponent({
    components: {
        Icon,
        ButtonCircular,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String as PropType<string | null>,
            default: null,
            required: false,
        },
        url: {
            type: String,
            required: true,
        },
        permission: {
            type: String as PropType<string | null>,
            default: null,
            required: false,
        },
        icon: {
            type: String as PropType<string | null>,
            default: null,
            required: false,
        },
    },

    computed: {
        hasPermission() {
            if (this.permission === null) {
                return true;
            }

            return this.$gate.allows(this.permission);
        }
    },

    methods: {
        route,

        onDoubleCLick() {
            window.location.href = this.url;
        },
    },
});
</script>

<style lang="css" scoped>

.container {
    background-color: white;
    border-radius: var(--card-border-radius);
    padding: 16px 24px;
    display: flex;
    align-items: center;

    main {
        flex-grow: 3;

        h3 {
            display: flex;
            align-items: center;
            gap: 8px;

            .icon {
                flex-basis: 24px;
                height: 24px;
            }
        }
    }

    footer {
        display: flex;
    }
}

</style>
