import {defineAsyncComponent} from 'vue';

// Register global components:
import ButtonCustom from "@/Vue/Common/ButtonCustom";
import ButtonCircular from "@/Vue/Common/ButtonCircular";
import ButtonPrimary from "@/Vue/Common/ButtonPrimary";
import ButtonSecondary from "@/Vue/Common/ButtonSecondary";
import Checkbox from "@/Vue/Common/Checkbox";
import Collapsible from "@/Vue/Common/Collapsible";
import ColorHexInput from "@/Vue/Common/ColorHexInput";
import Draggable from "@/Vue/Common/Draggable";
import Dropdown from "@/Vue/Common/Dropdown";
import Icon from "@/Vue/Common/Icon";
import NumberInput from "@/Vue/Common/NumberInput";
import PageHeader from "@/Vue/Common/PageHeader";
import RadioButtons from "@/Vue/Common/RadioButtons";
import StatusLabel from "@/Vue/Common/StatusLabel";
import Tabs from "@/Vue/Common/Tabs.vue";
import TagList from "@/Vue/Common/TagList";
import TextInput from "@/Vue/Common/TextInput";
import TextOutputField from "@/Vue/Common/TextOutputField";
import Pagination from "@/Vue/Common/Pagination";
import ModalAbout from "@/Vue/Modals/ModalAbout";
import ModalInstallation from "@/Vue/Modals/Installation/ModalInstallation";
import ModalSelectTenant from '@/Vue/Modals/SelectTenant/ModalSelectTenant';

// Register components for Laravel views:
import ArtisanCommands from "@/Vue/Admin/ArtisanCommands";
import PageAssetList from "@/Vue/Assets/PageAssetList";
import SidemenuItem from "@/Vue/Sidemenu/SidemenuItem.vue";
import SidemenuItemUserGuiding from "@/Vue/Sidemenu/SidemenuItemUserGuiding";
import PageSessionList from "@/Vue/Sessions/PageSessionList";
import PageSessionCreate from "@/Vue/Sessions/PageSessionCreate";
import PageSessionEdit from "@/Vue/Sessions/PageSessionEdit";
import PageTenantCreate from "@/Vue/Tenants/PageTenantCreate";
import PageTenantList from "@/Vue/Tenants/PageTenantList";
import PageTenantUpdate from "@/Vue/Tenants/PageTenantUpdate";
import ApiTokenPage from "@/Vue/Tokens/ApiTokenPage";
import PageUserList from "@/Vue/Users/PageUserList.vue";
import PageInstanceManagement from "@/Vue/InstanceManagement/PageInstanceManagement";
import CreateUserForm from "@/Vue/Users/CreateUserForm";
import DeleteUserForm from "@/Vue/Users/DeleteUserForm";
import UpdateUserForm from "@/Vue/Users/UpdateUserForm";
import Userguiding from "@/Vue/Bootstrap/Userguiding.vue";
import UserNavigationItem from "@/Vue/Users/UserNavigationItem";
import SetInitialPasswordPage from "@/Vue/Users/SetInitialPasswordPage";
import PageCourseCreate from "@/Vue/Courses/PageCourseCreate";
import PageCourseEdit from "@/Vue/Courses/PageCourseEdit";
import PageCourseList from "@/Vue/Courses/PageCourseList";
import PageCourseView from "@/Vue/Courses/PageCourseView";
import CreateAssetForm from "@/Vue/Assets/CreateAssetForm";
import PageImportList from "@/Vue/Import/PageImportList";
import PageUnitList from "@/Vue/Units/PageUnitList";
import PageUnitCreate from "@/Vue/Units/PageUnitCreate";
import PageUnitLaunch from "@/Vue/Units/PageUnitLaunch";
import PageModelViewer from "@/Vue/Tools/ModelViewer/PageModelViewer";
import PageLmsXApiLaunch from "@/Vue/Lms/xapi/PageLmsXApiLaunch";
import PageLmsXApiLaunchError from "@/Vue/Lms/xapi/PageLmsXApiLaunchError";
import PageTenantAcceptInvitation from "@/Vue/Tenants/PageTenantAcceptInvitation"
import PageStatistics from "@/Vue/Statistics/PageStatistics.vue";
import PermissionsTable from "@/Vue/Admin/PermissionsTable.vue";
import TranslationsTable from "@/Vue/Admin/TranslationsTable.vue";
import FeaturesTable from "@/Vue/Admin/FeaturesTable.vue";
import InstanceUserList from '@/Vue/Admin/InstanceUserList.vue';
import PageIdentityProviderIndex from '@/Vue/IdentityProviders/PageIdentityProviderIndex.vue';
import PageIdentityProviderCreate from '@/Vue/IdentityProviders/PageIdentityProviderCreate.vue';
import PageIdentityProviderEdit from '@/Vue/IdentityProviders/PageIdentityProviderEdit.vue';
import PageIdentityProviderShow from '@/Vue/IdentityProviders/PageIdentityProviderShow.vue';
import PageLogin from '@/Vue/Auth/PageLogin.vue';

// Behaviour Panels
import PanelBehaviourCollidable from "@/Vue/Inspector/Behaviours/PanelBehaviourCollidable";
import PanelBehaviourMovable from "@/Vue/Inspector/Behaviours/PanelBehaviourMovable";
import PanelBehaviourPhysics from "@/Vue/Inspector/Behaviours/PanelBehaviourPhysics";
import PanelBehaviourTeleportable from "@/Vue/Inspector/Behaviours/PanelBehaviourTeleportable";

// Wrapper for behaviours
import PanelBehaviour from "@/Vue/Inspector/Behaviours/PanelBehaviour";

// Variable Panels
import PanelVariableBool from "@/Vue/Inspector/Variables/PanelVariableBool";
import PanelVariableNumber from "@/Vue/Inspector/Variables/PanelVariableNumber";
import PanelVariableOperandNumber from "@/Vue/Inspector/Variables/PanelVariableOperandNumber";

// Wrapper for variables
import PanelVariable from "@/Vue/Inspector/Variables/PanelVariable";

// register components
export function registerComponents(app) {
    // Register global components:
    app.component('ButtonCustom', ButtonCustom);
    app.component('ButtonCircular', ButtonCircular);
    app.component('ButtonPrimary',  ButtonPrimary);
    app.component('ButtonSecondary', ButtonSecondary);
    app.component('Checkbox', Checkbox);
    app.component('Collapsible', Collapsible);
    app.component('ColorHexInput', ColorHexInput);
    app.component('Draggable', Draggable);
    app.component('Dropdown', Dropdown);
    app.component('Icon', Icon);
    app.component('NumberInput', NumberInput);
    app.component('PageHeader', PageHeader);
    app.component('RadioButtons',RadioButtons);
    app.component('StatusLabel', StatusLabel);
    app.component('Tabs', Tabs);
    app.component('TagList', TagList);
    app.component('TextInput', TextInput);
    app.component('TextOutputField', TextOutputField);
    app.component('Pagination', Pagination);
    app.component('ModalAbout', ModalAbout);
    app.component('ModalInstallation', ModalInstallation);
    app.component('ModalSelectTenant', ModalSelectTenant);

    // Register components for Laravel views:
    // @NOTE: Only kebab-case is working here since these components are loaded in Laravel views!

    // Load authoring async - this causes vite/rollup to create separate bundled with these dependencies.
    // This will reduce load sizes for learners drastically.
    app.component('authoring', defineAsyncComponent(() => import('@/Vue/Authoring/Authoring')));

    app.component('page-login', PageLogin);
    app.component('artisan-commands', ArtisanCommands);
    app.component('page-asset-list', PageAssetList);
    app.component('sidemenu-item', SidemenuItem);
    app.component('sidemenu-item-userguiding', SidemenuItemUserGuiding);
    app.component('page-session-list', PageSessionList);
    app.component('page-session-create', PageSessionCreate);
    app.component('page-session-edit', PageSessionEdit);
    app.component('page-tenant-create', PageTenantCreate);
    app.component('page-tenant-list', PageTenantList);
    app.component('page-tenant-update', PageTenantUpdate);
    app.component('api-token-page', ApiTokenPage);
    app.component('page-user-list', PageUserList);
    app.component('page-instance-management', PageInstanceManagement);
    app.component('create-user-form', CreateUserForm);
    app.component('delete-user-form', DeleteUserForm);
    app.component('update-user-form', UpdateUserForm);
    app.component('user-navigation-item', UserNavigationItem);
    app.component('userguiding', Userguiding);
    app.component('set-initial-password-page', SetInitialPasswordPage);
    app.component('page-course-create', PageCourseCreate);
    app.component('page-course-edit', PageCourseEdit);
    app.component('page-course-list', PageCourseList);
    app.component('page-course-view', PageCourseView);
    app.component('create-asset-form', CreateAssetForm);
    app.component('page-import-list', PageImportList);
    app.component('page-identity-provider-index', PageIdentityProviderIndex);
    app.component('page-identity-provider-create', PageIdentityProviderCreate);
    app.component('page-identity-provider-edit', PageIdentityProviderEdit);
    app.component('page-identity-provider-show', PageIdentityProviderShow);
    app.component('page-unit-list', PageUnitList);
    app.component('page-unit-create', PageUnitCreate);
    app.component('page-unit-launch', PageUnitLaunch);
    app.component('page-model-viewer', PageModelViewer);
    app.component('page-lms-xapi-launch', PageLmsXApiLaunch);
    app.component('page-lms-xapi-launch-error', PageLmsXApiLaunchError);
    app.component('page-statistics', PageStatistics);
    app.component('page-tenant-accept-invitation', PageTenantAcceptInvitation);
    app.component('permissions-table', PermissionsTable);
    app.component('translations-table', TranslationsTable);
    app.component('features-table', FeaturesTable);
    app.component('instance-user-list', InstanceUserList);

    // Behaviour Panels
    // Because of circular dependencies we have to register them globally for now
    app.component('PanelBehaviourCollidable', PanelBehaviourCollidable);
    app.component('PanelBehaviourMovable', PanelBehaviourMovable);
    app.component('PanelBehaviourPhysics', PanelBehaviourPhysics);
    app.component('PanelBehaviourTeleportable', PanelBehaviourTeleportable);

    // Wrapper for behaviours
    // This should be used instead of the individual behaviour panels.
    app.component('PanelBehaviour', PanelBehaviour);

    // Variable Panels
    // Because of circular dependencies we have to register them globally for now
    app.component('PanelVariableBool', PanelVariableBool);
    app.component('PanelVariableNumber', PanelVariableNumber);
    app.component('PanelVariableOperandNumber', PanelVariableOperandNumber);

    // Wrapper for variables
    // This should be used instead of the individual variable panels.
    app.component('PanelVariable', PanelVariable);
}
