// Last Generated by GenerateTestMocks command on 2024-02-26T14:02:53+00:00
const exportData = {};

    exportData.appData = {
        URL_HOME: "http://localhost",
        APP_TITLE: "3spin Learning",
        APP_RELEASE: "2024.2",
        APP_VERSION: "12.0.0",
        APP_RELEASE_VERSION: "2024.2 (12.0.0)",
        APP_BUILD: "",
        API_VERSION: "2.0.0",
        UNIT_DATA_VERSION: "3.0.0",
        HEADER_API_INFO: "API-Info",
        HEADER_API_PLATFORM: "API-Platform",
        HEADER_API_TENANT: "API-Tenant",
        HEADER_API_VERSION: "API-Version",
        THEME: {"wms":[],"app":{"colors":{"main":"0098A5FF","highlight":"FFFFFFFF"}}}
    };

    exportData.appRoutes = {
	"api.assets.archive": "http://localhost/api/assets/{asset}/archive",
	"api.assets.add_to_library": "http://localhost/api/assets/{assetWithFilesForPlatform}/add-to-library",
	"api.assets.create": "http://localhost/api/assets",
	"api.assets.delete": "http://localhost/api/assets/{asset}",
	"api.assets.delete.unused": "http://localhost/api/assets/unused",
	"api.assets.index": "http://localhost/api/assets",
	"api.assets.remove_from_library": "http://localhost/api/assets/{assetWithFilesForPlatform}/remove-from-library",
	"api.assets.restore_archived": "http://localhost/api/assets/{asset}/restore",
	"api.assets.update": "http://localhost/api/assets/{asset}",
	"api.courses.create": "http://localhost/api/courses",
	"api.courses.delete": "http://localhost/api/courses/{course}",
	"api.courses.details": "http://localhost/api/courses/{course}",
	"api.courses.enrollments.update": "http://localhost/api/courses/{course}/enrollments",
	"api.courses.index": "http://localhost/api/courses",
	"api.courses.units": "http://localhost/api/courses/{course}/units",
	"api.courses.update": "http://localhost/api/courses/{course}",
	"api.installer.quest.business.submit": "http://localhost/api/installer/quest/business",
	"api.learning_records.index": "http://localhost/api/learning-records",
	"api.learning_records.statistics": "http://localhost/api/learning-records/statistics",
	"api.services.cognitive_services.token": "http://localhost/api/services/cognitive-services/token",
	"api.features.local.index": "http://localhost/api/features/local",
	"api.features.local.create": "http://localhost/api/features/local",
	"api.features.local.delete": "http://localhost/api/features/local/{feature}",
	"api.statistics.index": "http://localhost/api/statistics",
	"api.tenants.create": "http://localhost/api/tenants",
	"api.tenants.update": "http://localhost/api/tenants/{tenant}",
	"api.tenants.delete": "http://localhost/api/tenants/{tenant}",
	"api.tokens.delete": "http://localhost/api/tokens/{token}",
	"api.tokens.deleteAll": "http://localhost/api/tokens",
	"api.tracking.tour-events.create": "http://localhost/api/tracking/tour-events",
	"api.units.authors": "http://localhost/api/units/{unit}/authors",
	"api.units.courses": "http://localhost/api/units/{unit}/courses",
	"api.units.create": "http://localhost/api/units",
	"api.units.delete": "http://localhost/api/units/{unit}",
	"api.units.details": "http://localhost/api/units/{unit}",
	"api.units.details.updated_at": "http://localhost/api/units/{unit}/updated-at",
	"api.units.duplicate": "http://localhost/api/units/{unit}/duplicate",
	"api.units.import-template": "http://localhost/api/units/{unit}/import-template",
	"api.units.imports.import": "http://localhost/api/units/import/{package}",
	"api.units.index": "http://localhost/api/units",
	"api.units.release": "http://localhost/api/units/{unit}/release",
	"api.units.revisions.create_unit": "http://localhost/api/units/{unit}/revisions/{unitRevision}/create-unit",
	"api.units.revisions.read": "http://localhost/api/units/{unit}/revisions/{unitRevision}",
	"api.units.update": "http://localhost/api/units/{unit}",
	"api.units.links.create": "http://localhost/api/units/{unit}/links",
	"api.units.links.delete": "http://localhost/api/units/links/{unitLink}",
	"api.units.links.read": "http://localhost/api/units/{unit}/links",
	"api.users.create": "http://localhost/api/users",
	"api.users.delete": "http://localhost/api/users/{user}",
	"api.users.index": "http://localhost/api/users",
	"api.users.tenants.index": "http://localhost/api/users/{user}/tenants",
	"api.users.tokens.create": "http://localhost/api/users/{user}/tokens",
	"api.users.tokens.index": "http://localhost/api/users/{user}/tokens",
	"api.manage.commands.run": "http://localhost/api/manage/commands/{command}",
	"assets.createForm": "http://localhost/assets/create",
	"assets.index": "http://localhost/assets",
	"courses.createForm": "http://localhost/courses/create",
	"courses.edit": "http://localhost/courses/{course}/edit",
	"courses.index": "http://localhost/courses",
	"courses.units.launch": "http://localhost/courses/{course}/units/{unit}/launch",
	"courses.view": "http://localhost/courses/{course}",
	"keepAlive": "http://localhost/keep-alive",
	"manage": "http://localhost/manage",
	"login": "http://localhost/login",
	"logout": "http://localhost/logout",
	"password.request": "http://localhost/password/reset",
	"statistics.index": "http://localhost/statistics",
	"tenants.index": "http://localhost/tenants",
	"tenants.createForm": "http://localhost/tenants/create",
	"tenants.updateForm": "http://localhost/tenants/{tenant}/edit",
	"tools.model-viewer.index": "http://localhost/tools/model-viewer",
	"units.createForm": "http://localhost/units/create",
	"units.imports.index": "http://localhost/units/import",
	"units.index": "http://localhost/units",
	"units.edit": "http://localhost/units/{unit}",
	"units.launch": "http://localhost/units/{unit}/launch",
	"units.links.show": "http://localhost/units/{unit}/links/{unitLinkWithUnit}",
	"units.lms.xapi.download": "http://localhost/units/{unit}/lms/xapi/download",
	"units.showUnitsForExport": "http://localhost/units/export",
	"user.tenants.current.put": "http://localhost/user/tenants/current",
	"users.index": "http://localhost/users",
	"users.createForm": "http://localhost/users/create",
	"users.updateForm": "http://localhost/users/{user}/edit"
};



export default exportData;