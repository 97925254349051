<template>
    <main
        id="layout-main"
        :data-saving="isSaving"
    >

        <PageHeader
            :page-title="trans('identity_providers.index.headline')"
        />

        <div id="layout-content">
            <div id="content">

                <ButtonPrimary
                    v-if="canCreateProvider"
                    v-tooltip="'buttons.identity_providers.create'"
                    :href="route('identity_providers.create')"
                    caption="identity_providers.index.btn_create"
                    icon="icon_add"
                />

                <div class="provider-list">
                    <identity-provider-list-item
                        v-for="provider in identityProviders"
                        :key="provider.uid"
                        :provider="provider"
                        @delete-click="onDeleteClick"
                    />
                </div>

            </div>

            <!-- Modals go here -->
            <ModalDeleteIdentityProvider />
            <ModalProgress />
        </div>
    </main>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import IdentityProvider from '@/Models/IdentityProviders/IdentityProvider';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import {permission, route, trans} from '@/Utility/Helpers';
import IdentityProviderListItem from '@/Vue/IdentityProviders/IdentityProviderListItem.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {identityProviderServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import AuthorizationError from '@/Errors/AuthorizationError';
import EventType from '@/Utility/EventType';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import ModalDeleteIdentityProvider from '@/Vue/Modals/ModalDeleteIdentityProvider.vue';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({
    components: {
        ModalDeleteIdentityProvider,
        ModalProgress,
        ButtonPrimary,
        IdentityProviderListItem,
        PageHeader
    },

    props: {
        identityProvidersJson: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            identityProviderService: inject(identityProviderServiceKey)!,

            identityProviders: JSON.parse(this.identityProvidersJson)
                .map((provider: object) => new IdentityProvider(provider)) as IdentityProvider[],

            events: new Map([
                [EventType.MODAL_DELETE_IDENTITY_PROVIDER_APPLY, this.onDeleteProviderConfirmed],
                [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],
            ]),
        };
    },

    computed: {
        canCreateProvider() {
            return permission(Permission.IdentityProvidersCreate());
        },

        isSaving() {
            if (this.identityProviderService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        }
    },

    mounted() {
        // Add global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });
    },

    beforeUnmount() {
        // Remove global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {
        route,
        trans,

        onDeleteProviderConfirmed(provider: IdentityProvider) {
            this.identityProviderService
                .deleteIdentityProvider(provider.uid)
                .then(() => window.location.reload())
                .catch(this.onErrorApi);
        },

        onDeleteClick(provider: IdentityProvider) {
            this.$globalEvents.emit(EventType.MODAL_DELETE_IDENTITY_PROVIDER_SHOW, provider);
        },

        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
            }
            this.$root!.showErrorDialog(error);
        },

        onBeforeUnload() {
            this.identityProviderService.cancelRequests();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },
    },

});
</script>

<style lang="css" scoped>

#content {
    .provider-list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 12px 0 30px 0;

        max-width: var(--container-max-width);
        min-width: 670px;
    }
}

</style>
