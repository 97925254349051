<template>
    <article class="help">
        <h2>{{ trans('identity_providers.help.headline') }}</h2>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="trans('identity_providers.help.introduction_1')" />


        <h3>{{ trans('labels.domain') }}</h3>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="trans('identity_providers.help.domain_1')" />


        <h3>{{ trans('labels.client_id') }}</h3>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="trans('identity_providers.help.client_id_1')" />

        <img alt="" class="essentials" src="/images/identity-provider/entra_provider_essentials_client_id.png">


        <h3>{{ trans('labels.client_secret') }}</h3>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="trans('identity_providers.help.client_secret_1')" />


        <h3>{{ trans('labels.entra_tenant_id') }}</h3>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="trans('identity_providers.help.tenant_id_1')" />

        <img alt="" class="essentials" src="/images/identity-provider/entra_provider_essentials_tenant.png">


        <h3>{{ trans('labels.proxy') }}</h3>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="trans('identity_providers.help.proxy_1')" />
    </article>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {trans} from '@/Utility/Helpers';

export default defineComponent({
    methods: {
        trans
    }
});
</script>

<style lang="css" scoped>
.help {
    background-color: white;
    border-radius: var(--card-border-radius);
    padding: 28px 32px;

    h3 {
        margin-top: 16px;
    }

    img {
        width: 100%;
        margin: 8px auto 16px auto;
        padding: 8px;
        display: block;
        border: var(--forminput-border);

        &.essentials {
            max-width: 416px;
        }
    }
}
</style>
