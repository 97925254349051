<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <IdentityProviderLogoLink
                            v-if="user.identity_provider"
                            :provider="user.identity_provider"
                            class="identity-provider-logo"
                        />
                        {{ trans('users.update.headline') }}
                    </div>
                    <div class="card-body">
                        <form ref="form" class="form-user-update" method="post">
                            <div v-if="isManagedUser" class="alert alert-secondary">
                                {{ trans('users.update.managed_user_update_hint') }}
                            </div>
                            <UserFormTextInput
                                :key="'firstname'+key"
                                :model="form"
                                property="firstname"
                                :required="true"
                                :maxlength="50"
                                :label="trans('labels.firstname')"
                                :disabled="isManagedUser || !canChangePersonalData"
                                :validation-errors="validationErrors('firstname')"
                                @change="removeValidationError('firstname')"
                            />

                            <UserFormTextInput
                                :key="'lastname'+key"
                                :model="form"
                                property="lastname"
                                :required="true"
                                :maxlength="50"
                                :label="trans('labels.lastname')"
                                :disabled="isManagedUser || !canChangePersonalData"
                                @change="removeValidationError('lastname')"
                            />

                            <UserFormTextInput
                                :key="'email'+key"
                                :model="form"
                                property="email"
                                type="email"
                                :required="true"
                                :maxlength="254"
                                :label="trans('labels.email')"
                                :disabled="isManagedUser || !canChangePersonalData"
                                :validation-errors="validationErrors('email')"
                                @change="removeValidationError('email')"
                            />

                            <UserFormTextInput
                                :key="'avatar_url'+avatarLoadKey"
                                ref="avatarUrlInput"
                                :label="trans('labels.avatar_url')"
                                :maxlength="255"
                                :model="form"
                                property="avatar_url"
                                :required="false"
                                :validation-errors="validationErrors('avatar_url')"
                                placeholder="https://models.readyplayer.me/my-avatar.glb"
                                type="url"
                                :disabled="!canChangePersonalData"
                                @change="removeValidationError('avatar_url')"
                            />

                            <div class="form-group row row-avatar-preview">

                                <div class="offset-md-4 col-md-6">
                                    <ReadyPlayerMeAvatarPreview
                                        :avatar_glb_url="cacheBustedAvatarUrl"
                                    />
                                    <ButtonSecondary
                                        caption="users.create.change_avatar"
                                        :disabled="!canChangePersonalData"
                                        @trigger="onChangeAvatarClicked"
                                    />
                                </div>
                            </div>

                            <div v-if="canChangeUserRole" class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">{{ trans('labels.role') }}</label>
                                <div class="col-md-6">
                                    <Dropdown
                                        :key="'userrole'+key"
                                        :model="form"
                                        property="userroles_uid"
                                        :options="userRoleOptions"
                                        :required="true"
                                        :disabled="isManagedUser"
                                        :class="(validationErrors('userroles_uid').length > 0 ? ' is-invalid' : '')"
                                        @select="removeValidationError('userroles_uid')"
                                    />

                                    <span v-if="validationErrors('userroles_uid').length > 0" class="invalid-feedback" role="alert">
                                        <strong v-html="validationErrors('userroles_uid')[0]" />
                                    </span>
                                </div>
                            </div>

                            <div v-if="canChangeTenantRole" class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">{{ trans('labels.tenant_role') }}</label>
                                <div class="col-md-6">
                                    <Dropdown
                                        :key="'tenantrole'+key"
                                        :model="form"
                                        property="tenant_role_uid"
                                        :options="tenantRoleOptions"
                                        :required="true"
                                        :disabled="isManagedUser"
                                        :class="(validationErrors('tenant_role_uid').length > 0 ? ' is-invalid' : '')"
                                        @select="removeValidationError('tenant_role_uid')"
                                    />
                                    <span v-if="validationErrors('tenant_role_uid').length > 0" class="invalid-feedback" role="alert">
                                        <strong v-html="validationErrors('tenant_role_uid')[0]" />
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="buttons col-md-6 offset-md-4">
                                    <ButtonPrimary
                                        type="submit"
                                        :disabled="isSubmitting"
                                        caption="labels.save"
                                        @trigger="onSubmit"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <ModalProgress />
        <ModalReadyPlayerMe avatar-type="user" />
        <ModalNotification />
    </div>
</template>

<script>
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ModalReadyPlayerMe from '@/Vue/Modals/ModalReadyPlayerMe.vue';
import DropdownOption from '@/Utility/DropdownOption';
import EventType from '@/Utility/EventType';
import UserFormTextInput from '@/Vue/Users/UserFormTextInput';
import {permission, route, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import ReadyPlayerMeAvatarPreview from '@/Vue/Users/ReadyPlayerMeAvatarPreview';
import RequestError from '@/Errors/RequestError';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import {UserRole} from '@/Models/User/UserRole';
import {TenantRole} from '@/Models/Tenant/TenantRole';
import User from '@/Models/User/User';
import IdentityProviderLogoLink from '@/Vue/IdentityProviders/IdentityProviderLogoLink.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import Dropdown from '@/Vue/Common/Dropdown.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';

export default {
    name: 'UpdateUserForm',
    components: {
        ButtonPrimary,
        Dropdown,
        ButtonSecondary,
        IdentityProviderLogoLink,
        ModalProgress,
        ModalNotification,
        ModalReadyPlayerMe,
        UserFormTextInput,
        ReadyPlayerMeAvatarPreview,
    },
    props: {
        userJson: {
            type: Object,
            required: true,
        },
        userRoles: {
            type: Array,
            default() {
                return [];
            }
        },
        tenantRoles: {
            type: Array,
            default() {
                return [];
            }
        },
        url: {
            type: String,
        },
    },
    data() {
        return {
            user: new User(this.userJson),
            isSubmitting: false,
            form: {
                firstname: null,
                lastname: null,
                avatar_url: null,
                email: null,
                userroles_uid: null,
                tenant_role_uid: null,
            },
            defaultForm: {},
            errors: {},
            key: 0,
            /**
             * Used for cache busting for avatar url - because url might not change,
             * but the underlying image data does.
             * @type number
             */
            avatarLoadKey: 0,
        }
    },

    computed: {

        /**
         * @return {string|null}
         */
        cacheBustedAvatarUrl() {
            if (!this.form.avatar_url) {
                return null;
            }

            return `${this.form.avatar_url}&t=${this.avatarLoadKey}`;
        },

        /**
         * Get the UID for the initial tenant role that should be selected.
         *
         * @returns {string|null}
         */
        initialTenantRole() {
            if (this.user.tenant_role === null) {
                return null;
            }

            let role = this.tenantRoles.find(role => role.uid === this.user.tenant_role.uid);

            return role.uid;
        },

        /**
         * Get the UID for the initial user role that should be selected.
         *
         * @returns {string|null}
         */
        initialUserRole() {
            let role = this.userRoles.find(role => role.name === this.user.role);

            return role !== undefined ? role.uid : null;
        },

        /**
         * Tenants to show in the dropdown
         *
         * @returns {DropdownOption[]}
         */
        userRoleOptions() {
            const userRoleNames = Object.values(UserRole);

            return this.userRoles
                .toSorted((role1, role2) => userRoleNames.indexOf(role1.name) - userRoleNames.indexOf(role2.name))
                .map(role => new DropdownOption({
                    caption: trans('userroles.' + role.name),
                    value: role.uid
                }));
        },

        /**
         * Tenants to show in the dropdown
         *
         * @returns {DropdownOption[]}
         */
        tenantRoleOptions() {
            const tenantRoleNames = Object.values(TenantRole.Name);

            return this.tenantRoles
                .toSorted((role1, role2) => tenantRoleNames.indexOf(role1.name) - tenantRoleNames.indexOf(role2.name))
                .map(role => new DropdownOption({
                    caption: trans('tenant_roles.' + role.name),
                    value: role.uid
                }));
        },

        /**
         * Can the current user change the users' tenant role
         *
         * @returns {boolean}
         */
        canChangeTenantRole() {
            return permission(Permission.TenantsAssignRole()) && this.user.tenant_role !== null;
        },

        /**
         * Can the current user change the users' tenant role
         *
         * @returns {boolean}
         */
        canChangeUserRole() {
            return permission(Permission.UsersAssignUserRole()) && this.initialUserRole !== null;
        },

        isManagedUser() {
            return this.user.is_managed_user;
        },

        canChangePersonalData() {
            const canUpdateAny = permission(Permission.UsersUpdateAny());
            const isOwnUser = this.currentUser.uid === this.user.uid;

            return (isOwnUser || canUpdateAny);
        },

        currentUser() {
            return window.currentUser;
        },

    },

    beforeMount() {

        this.form.firstname = this.user.firstname;
        this.form.lastname = this.user.lastname;
        this.form.email = this.user.email;
        this.form.avatar_url = this.user.avatar_url;

        this.form.userroles_uid = this.initialUserRole;
        this.form.tenant_role_uid = this.initialTenantRole;

        this.defaultForm = {...this.form};
    },

    mounted() {
        this.$globalEvents.on(EventType.MODAL_READY_PLAYER_ME_APPLY, this.onAvatarCreated);
    },

    beforeUnmount() {
        this.$globalEvents.off(EventType.MODAL_READY_PLAYER_ME_APPLY, this.onAvatarCreated);
    },
    methods: {
        route,
        trans,

        /**
         * Get the validation errors for a specific field.
         *
         * @param property
         * @returns {Array}
         */
        validationErrors(property) {
            return this.errors.hasOwnProperty(property) ? this.errors[property] : [];
        },

        /**
         * Clear the validating errors for a specific field.
         *
         * @param property
         */
        removeValidationError(property) {
            delete this.errors[property];
        },

        onChangeAvatarClicked(event) {
            event.preventDefault();

            this.$globalEvents.emit(EventType.MODAL_READY_PLAYER_ME_SHOW);
        },

        onAvatarCreated(avatarUrl) {
            this.form.avatar_url = avatarUrl;

            // cache busting for avatar preview - because url might not change, but the underlying image data does
            this.avatarLoadKey = Math.random();
        },

        /**
         * Click handler for the submit button
         *
         * @param event
         */
        onSubmit(event) {
            event.preventDefault();

            const form = this.$refs.form;

            if (this.isSubmitting || !form.reportValidity()) {
                return;
            }

            this.isSubmitting = true;
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('users.create.modals.updating.title'));
            const formData = {...this.form};
            formData._method = 'patch';

            // Only send tenant_role_uid if the user has the permission to change it
            if (!this.canChangeTenantRole) {
                delete formData.tenant_role_uid;
            }

            // Only send userroles_uid if the user has the permission to change it
            if (!this.canChangeUserRole) {
                delete formData.userroles_uid;
            }

            // Default value for avatar_url is null
            if (!formData.avatar_url) {
                formData.avatar_url = null;
            }

            if (!this.canChangePersonalData) {
                delete formData.firstname;
                delete formData.lastname;
                delete formData.avatar_url;
                delete formData.email;
            }

            if (this.isManagedUser) {
                delete formData.firstname;
                delete formData.lastname;
                delete formData.email;
                delete formData.userroles_uid;
                delete formData.tenant_role_uid;
            }

            axios({
                url: this.url,
                method: "post",
                data: formData,
            })
            .then(() => {
                this.$toast.success(trans('users.update.update_success'));
                this.errors = {};
            })
            .catch((error) => {
                this.errors = error.validationErrors || {};

                // Show error dialog unless it's a validation error:
                if (!(error instanceof RequestError && error.isValidationError)) {
                    this.$root.showErrorDialog(error);
                }
            })
            .finally(() => {
                this.isSubmitting = false;
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .identity-provider-logo {
        margin-right: 8px;
    }

    .form-user-update .avatar-preview {
        height: 100px;
    }

    .form-user-update .row-avatar-preview > div {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
    }
</style>
