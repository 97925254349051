<template>

    <section>
        <form :action="forRoute" :method="formMethod">
            <header>
                <h3 class="title">
                    {{ title }}
                </h3>
                <p v-if="subtitle">
                    {{ subtitle }}
                </p>
            </header>

            <input :value="csrf" name="_token" type="hidden">

            <main v-if="isIdentifierMode">

                <div class="field">
                    <label for="email">{{ trans('labels.email') }}</label>
                    <input
                        id="email"
                        ref="email"
                        :placeholder="trans('labels.email')"
                        :value="identifier"
                        autofocus
                        name="email"
                        required
                        type="email"
                    >
                </div>

                <div class="errors">
                    <p v-for="message in errorMessages" :key="message" class="error">
                        {{ message }}
                    </p>
                </div>

                <ButtonPrimary :caption="trans('labels.proceed')" />
            </main>

            <main v-else>

                <div class="field">
                    <label for="email">{{ trans('labels.email') }}</label>
                    <input
                        id="email"
                        :placeholder="trans('labels.email')"
                        :value="identifier"
                        name="email"
                        readonly
                        required
                        type="email"
                    >
                    <ButtonCircular
                        :title="trans('labels.edit')"
                        class="small"
                        icon="icon_edit"
                        type="button"
                        @trigger="editIdentifier"
                    />
                </div>

                <div class="field">
                    <label for="password">{{ trans('labels.password') }}</label>
                    <input
                        id="password"
                        :placeholder="trans('labels.password')"
                        autofocus
                        name="password"
                        required
                        type="password"
                    >
                </div>

                <div class="errors">
                    <p v-for="message in errorMessages" :key="message" class="error">
                        {{ message }}
                    </p>
                </div>

                <fieldset class="meta">
                    <a v-if="showForgotPasswordLink" :href="route('password.request')" class="forgot-password">
                        {{ trans('auth.forgot_password_link') }}
                    </a>

                    <div v-if="showRememberMeCheckbox" class="checkbox-wrapper">
                        <input
                            id="remember"
                            v-model="rememberMe"
                            name="remember"
                            true-value="on"
                            type="checkbox"
                        >
                        <label for="remember">{{ trans('auth.remember_me') }}</label>
                    </div>
                </fieldset>

                <ButtonPrimary :caption="trans('labels.login')" />
            </main>

            <footer v-if="showWhatsNewFooter">
                <h4>{{ trans('labels.feature_timeline.headline') }}</h4>
                <p>{{ trans('labels.feature_timeline.text') }}</p>
                <a
                    :href="timelineUrl"
                    class="link-external"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {{ trans('labels.feature_timeline.button') }}
                </a>
            </footer>
        </form>

        <StatusLabel :config="versionLabelConfig" />
    </section>

</template>

<script lang="ts">
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import {route, trans} from '@/Utility/Helpers';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import StatusLabel from '@/Vue/Common/StatusLabel.vue';
import StatusLabelConfig from '@/Utility/StatusLabelConfig';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import appdata from '@/Tests/__mocks__/appdata';

type Mode = 'identifier' | 'password';

export default defineComponent({
    components: {
        ButtonCircular,
        StatusLabel,
        ButtonPrimary,
    },

    props: {
        csrf: {
            type: String,
            required: true,
        },
        initialMode: {
            type: String as PropType<Mode>,
            default: 'identifier'
        },
        initialIdentifier: {
            type: String as PropType<string | null>,
            default: null,
        },
        initialRememberMe: {
            type: String,
            default: '',
        },
        errorMessages: {
            type: Array as PropType<string[]>,
            default() {
                return [];
            }
        },
        showWhatsNewFooter: {
            type: Boolean,
            default: false,
        },
        showForgotPasswordLink: {
            type: Boolean,
            default: true,
        },
        showRememberMeCheckbox: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            mode: this.initialMode,
            identifier: this.initialIdentifier,
            rememberMe: this.initialRememberMe,
        };
    },

    computed: {
        appdata() {
            return appdata;
        },

        isIdentifierMode() {
            return this.mode === 'identifier';
        },

        forRoute() {
            return this.isIdentifierMode ?
                route('auth.provider.get') :
                route('login');
        },

        formMethod() {
            return this.isIdentifierMode ? 'get' : 'post';
        },

        title() {
            return this.isIdentifierMode ?
                trans('auth.headline_identifier') :
                trans('auth.headline_password');
        },

        subtitle() {
            return this.isIdentifierMode ?
                trans('auth.subline_identifier') :
                trans('auth.subline_password');
        },

        versionLabelConfig(): StatusLabelConfig {
            return new StatusLabelConfig({
                caption: window.appData.APP_RELEASE_VERSION,
            });
        },

        timelineUrl() {
            return window.appData.FEATURES_TIMELINE_URL;
        },
    },

    methods: {
        route,
        trans,

        editIdentifier() {
            this.mode = 'identifier';
        },
    }
});
</script>

<style lang="css" scoped>

section {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;

    background: transparent url('/images/background_login.jpg') no-repeat scroll left top;
    background-size: cover;

    form {
        width: 436px;
        padding: var(--modal-padding);
        background-color: var(--background-color-white);
        border-radius: var(--card-border-radius);
        box-shadow: var(--card-box-shadow);
        z-index: 5;

        header {
            margin-bottom: 24px;
        }

        main {
            .field {
                position: relative;
                margin: 12px 0;

                label {
                    position: absolute;
                    top: 13px;
                    left: 10px;
                    padding: 1px 6px;
                    color: var(--color-anthracite80);
                    background-color: var(--background-color-white);
                    transition: transform ease-in-out 0.1s;
                }

                /* label for focused or filled input fields */

                &:has(input:not(:placeholder-shown)) > label,
                &:has(input:focus-visible) > label {
                    transform: scale(0.9) translateY(-110%);
                }

                /* label for focused fields */

                &:has(input:focus-visible:not(:read-only)) > label {
                    color: var(--color-primary-hover);
                }

                /* label for invalid fields */

                &:has(input:user-invalid) > label {
                    color: var(--color-signal);
                }

                input[type="email"],
                input[type="password"] {
                    width: 100%;
                    appearance: none;
                    height: 48px;
                    padding: 0 16px;
                    border: var(--forminput-border);
                    border-radius: var(--forminput-border-radius);
                    transition: border-color 0.1s;
                    font-size: 16px;

                    /* hide placeholder - we use the label instead */

                    &::placeholder {
                        color: transparent;
                        -webkit-text-fill-color: transparent;
                    }

                    &:focus-visible:not(:read-only) {
                        border-color: var(--color-primary-hover);
                    }

                    &:user-invalid {
                        border-color: var(--color-signal);
                    }

                    & + .btn-circular.small {
                        position: absolute;
                        top: 12px;
                        right: 12px;
                    }
                }
            }

            .checkbox-wrapper {
                display: flex;
                gap: 8px;
                align-items: center;
            }

            .errors {
                color: var(--color-signal);

                ~ .meta {
                    margin-top: 16px;
                }
            }

            fieldset.meta {
                display: flex;
                justify-content: space-between;

                &:empty {
                    display: none;
                }
            }

            & > button {
                width: 100%;
                margin-top: 16px;
                height: 40px;
                border-radius: 20px;
            }
        }

        footer {
            margin-top: 16px;
            padding-top: 16px;
            border-top: var(--forminput-border);
        }
    }

    .status-label {
        position: absolute;
        top: 3vw;
        right: 2vw;
    }
}

/**
 * Small viewports will be used by mobile screens and the in-app browser.
 * We omit the background and make the whole form fullscreen.
 */
@media (max-width: 500px) {
    section {
        align-items: start;
        background: var(--background-color-white);

        form {
            width: 100%;
            box-shadow: none;
        }

        .status-label {
            display: none;
        }
    }
}

</style>
