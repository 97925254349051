import {trans} from '@/Utility/Helpers/trans';

export enum IdentityProviderDriverId {
    azure = 'azure',
    laravelpassport = 'laravelpassport',
}

class IdentityProviderDriver {

    constructor(readonly id: IdentityProviderDriverId) {
    }

    get logo() {
        switch (this.id) {
            case IdentityProviderDriverId.azure:
            default:
                return '/images/logos/logo_entra.svg';
        }
    }

    get name() {
        return trans(`identity_providers.driver_names.${this.id}`);
    }
}

const IdentityProviderDriverList: Record<IdentityProviderDriverId, IdentityProviderDriver> =
    Object.assign({}, ...Object
        .values(IdentityProviderDriverId)
        .map(key => ({ [key]: new IdentityProviderDriver(key) }))
    );

export default IdentityProviderDriverList;
