import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import {route, trans} from '@/Utility/Helpers';
import type {AxiosRequestConfig} from 'axios';
import axios from 'axios';
import IdentityProvider from '@/Models/IdentityProviders/IdentityProvider';
import type {IdentityProviderDriverId} from '@/Models/IdentityProviders/IdentityProviderDriver';

export type UpdateIdentityProviderParameters = {
    name?: string;
    domain?: string;
    client_id?: string;
    client_secret?: string;
    configuration?: object | null;
}

export type CreateIdentityProviderParameters = {
    driver_id?: IdentityProviderDriverId;
    name?: string;
    domain?: string;
    client_id?: string;
    client_secret?: string;
    configuration?: object | null;
}

export default class IdentityProviderService {

    public isLoading: boolean = false;
    public isSaving: boolean = false;

    private abortController = new AbortController();

    /**
     * Cancel any ongoing requests
     */
    async cancelRequests(): Promise<any> {
        this.abortController.abort();
        this.abortController = new AbortController();

        return Promise.resolve('Requests canceled');
    }

    async updateIdentityProvider(
        providerUid: string,
        updateParameters: UpdateIdentityProviderParameters
    ): Promise<IdentityProvider> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .patch(
                route('api.identity_providers.update', { identity_provider: providerUid }),
                updateParameters,
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return this.parseProvider(data.data);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    async deleteIdentityProvider(providerUid: string): Promise<void> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .delete(
                route('api.identity_providers.delete', { identity_provider: providerUid }),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(() => {
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    async createIdentityProvider(
        createParameters: CreateIdentityProviderParameters
    ): Promise<IdentityProvider> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .post(
                route('api.identity_providers.create'),
                createParameters,
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return this.parseProvider(data.data);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    /**
     * Tries to parse the given provider data into a valid identity provider.
     * Will print and throw usable errors when this fails.
     */
    private parseProvider(providerData: any): IdentityProvider {
        try {
            return new IdentityProvider(providerData);
        } catch (ex) {
            console.error(
                'IdentityProviderService->parseProvider(): API returned invalid or incompatible provider data.',
                providerData,
                ex
            );
            throw new Error(trans('errors.identity_provider.invalid_data'));
        }
    }


    /**
     * @returns {Promise<string>} newly created api key for user provisioning
     */
    async enableUserProvisioning(providerUid: string): Promise<string> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .post(
                route('api.identity_providers.user_provisioning.create', { identity_provider: providerUid }),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return data;
            })

            .finally(() => {
                this.isSaving = false;
            });
    }

    async removeUserProvisioning(providerUid: string): Promise<void> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .delete(
                route('api.identity_providers.user_provisioning.delete', { identity_provider: providerUid }),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(() => {
            })
            .finally(() => {
                this.isSaving = false;
            });
    }
}
