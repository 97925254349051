<template>
    <main
        id="layout-main"
        :data-saving="isSaving"
    >

        <div id="layout-content">
            <div id="content">

                <div class="column">
                    <form ref="form">

                        <h1 class="headline">
                            <Icon name="icon_identity-provider" />
                            {{ trans('identity_providers.create.headline') }}
                        </h1>

                        <div class="fields">
                            <fieldset class="drivers">
                                <legend>{{ trans('labels.authorization_driver') }}</legend>
                                <input
                                    v-for="driver in IdentityProviderDriverList"
                                    :key="driver.id"
                                    v-model="form.driver_id"
                                    :title="driver.name"
                                    :value="driver.id"
                                    name="driver_id"
                                    type="radio"
                                >
                            </fieldset>

                            <TextInput
                                :label="trans('labels.name')"
                                :maxlength="255"
                                :model="form"
                                :placeholder="trans('identity_providers.placeholders.name')"
                                :validation-errors="validationErrors('name')"
                                property="name"
                                required
                                @change="removeValidationErrors('name')"
                            />

                            <TextInput
                                :label="trans('labels.domain')"
                                :maxlength="255"
                                :model="form"
                                :placeholder="trans('identity_providers.placeholders.domain')"
                                :validation-errors="validationErrors('domain')"
                                property="domain"
                                required
                                @change="removeValidationErrors('domain')"
                            />

                            <TextInput
                                :label="trans('labels.client_id')"
                                :maxlength="255"
                                :model="form"
                                :placeholder="trans('identity_providers.placeholders.client_id')"
                                :validation-errors="validationErrors('client_id')"
                                property="client_id"
                                required
                                @change="removeValidationErrors('client_id')"
                            />

                            <TextInput
                                :label="trans('labels.client_secret')"
                                :maxlength="255"
                                :model="form"
                                :placeholder="trans('identity_providers.placeholders.client_secret')"
                                :validation-errors="validationErrors('client_secret')"
                                property="client_secret"
                                required
                                type="password"
                                @change="removeValidationErrors('client_secret')"
                                @focus="e => e.target.select()"
                            />

                            <TextInput
                                :label="trans('labels.entra_tenant_id')"
                                :maxlength="255"
                                :model="form.configuration"
                                :placeholder="trans('identity_providers.placeholders.tenant')"
                                :validation-errors="validationErrors('configuration.tenant')"
                                property="tenant"
                                required
                                @change="removeValidationErrors('configuration.tenant')"
                            />

                            <TextInput
                                :label="trans('labels.proxy')"
                                :maxlength="255"
                                :model="form.configuration"
                                :placeholder="trans('identity_providers.placeholders.proxy')"
                                :validation-errors="validationErrors('configuration.proxy')"
                                property="proxy"
                                type="url"
                                @change="removeValidationErrors('configuration.proxy')"
                            />
                        </div>

                        <div class="buttons">
                            <ButtonSecondary
                                :href="route('identity_providers.index')"
                                caption="identity_providers.create.btn_cancel"
                            />
                            <ButtonPrimary :caption="trans('labels.save')" @trigger.prevent="onSave" />
                        </div>
                    </form>
                </div>

                <div class="column">
                    <identity-provider-help />
                </div>

            </div>

            <!-- Modals go here -->
            <ModalProgress />
            <ModalNotification />
        </div>

    </main>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {route, trans} from '@/Utility/Helpers';
import TextInput from '@/Vue/Common/TextInput.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import {identityProviderServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import type RequestError from '@/Errors/RequestError';
import EventType from '@/Utility/EventType';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import {provider} from 'std-env';
import type {CreateIdentityProviderParameters} from '@/Services/IdentityProviderService';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import Icon from '@/Vue/Common/Icon.vue';
import IdentityProviderDriverList, {IdentityProviderDriverId} from '@/Models/IdentityProviders/IdentityProviderDriver';
import IdentityProviderHelp from '@/Vue/IdentityProviders/IdentityProviderHelp.vue';

export default defineComponent({
    components: {
        IdentityProviderHelp,
        Icon,
        ButtonSecondary,
        ModalNotification,
        ModalProgress,
        ButtonPrimary,
        TextInput,
    },

    data() {
        const defaultForm: CreateIdentityProviderParameters = {
            driver_id: IdentityProviderDriverId.azure,
            configuration: {
                proxy: null,
            }
        };

        return {
            identityProviderService: inject(identityProviderServiceKey)!,
            form: defaultForm,
            errors: {} as Record<string, string[]>,
        };
    },

    computed: {
        IdentityProviderDriverList() {
            return IdentityProviderDriverList;
        },

        formHtml() {
            return this.$refs.form as HTMLFormElement;
        },

        provider() {
            return provider;
        },

        isSaving() {
            if (this.identityProviderService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        }

    },

    methods: {
        route,
        trans,

        validationErrors(property: string): string[] {
            return this.errors[property] || [];
        },

        removeValidationErrors(property: string) {
            delete this.errors[property];
        },

        onSave() {
            if (!this.formHtml.reportValidity()) {
                return;
            }

            this.identityProviderService
                .createIdentityProvider(this.form)
                .then((provider) => {
                    window.location.href = route('identity_providers.edit', { identity_provider: provider.uid });
                })
                .catch(this.onApiError);
        },

        onApiError(error: RequestError) {
            if (error.isValidationError) {
                this.errors = error.validationErrors;
                return;
            }

            this.$root!.showErrorDialog(error);
        }
    }
});
</script>

<style lang="css" scoped>

#content {
    display: grid;
    grid-template-columns: minmax(min-content, 700px) minmax(min-content, 480px);
    justify-content: center;
    gap: 32px;
    padding: 32px;

    .column {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    form {
        background-color: white;
        border-radius: var(--card-border-radius);
        padding: 30px 48px;

        .headline {
            margin-bottom: 56px;
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;
        }

        .fields {

            .drivers {
                display: flex;
                margin-bottom: var(--forminput-spacing);
                gap: 0 var(--forminput-spacing);
                flex-wrap: wrap;
                justify-content: center;

                legend {
                    margin-bottom: 8px;
                    text-align: center;
                    font-family: var(--font-family-condensed-demibold);
                    font-size: var(--font-size-default);
                }

                input[type="radio"] {
                    width: 80px;
                    aspect-ratio: 1;
                    appearance: none;
                    background-image: url('/images/logos/logo_entra.svg');
                    background-size: 80%;
                    background-position: center;
                    background-repeat: no-repeat;
                    border: var(--forminput-border);
                    border-radius: var(--forminput-border-radius);

                    &:hover {
                        border-color: var(--color-primary-hover);
                    }

                    &:focus-visible {
                        outline: var(--forminput-border);
                        outline-color: var(--color-primary);
                        outline-offset: 2px;
                    }

                    &:checked {
                        border-color: var(--color-primary);
                    }
                }
            }

            .textinput {
                &:deep(label) {
                    padding: 0 0 8px 0;
                    font-family: var(--font-family-condensed-demibold);

                    &:has(+ :required):after {
                        content: ' *';
                    }
                }
            }
        }

        .buttons {
            margin-top: 32px;
            display: flex;
            justify-content: end;
        }
    }
}

</style>
