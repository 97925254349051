<template>
    <table class="table table-bordered">
        <thead>
            <tr class="table-active">
                <th colspan="2">Permission</th>
                <th colspan="6">User Role</th>
                <th colspan="4">Tenant Role</th>
            </tr>
            <tr class="table-active">

                <template v-for="(value, key) in permissions[Object.keys(permissions)[0]]">
                    <th v-if="typeof value === 'string'" class="text-capitalize">{{ key }}</th>
                    <template v-else>
                        <th v-for="(roleValue, roleName) in value">
                            {{ roleTrans(key, roleName) }}
                        </th>
                    </template>
                </template>
            </tr>
        </thead>
        <tbody>
            <tr v-for="permission in permissions">
                <template v-for="(value, key) in permission">
                    <td v-if="typeof  value === 'string'">{{ value }}</td>
                    <template v-else-if="typeof value === 'object'">
                        <template v-for="(roleValue, roleName) in value">
                            <td v-if="typeof roleValue === 'boolean'" class="text-center">
                                <Icon v-if="roleValue" name="icon_check" class="icon-check-circle round"/>
                            </td>
                            <td v-else>{{ roleValue }}</td>
                        </template>
                    </template>
                </template>
            </tr>
        </tbody>


    </table>
</template>

<script>
import Icon from "@/Vue/Common/Icon.vue";
import {trans} from "@/Utility/Helpers";

export default {
    name: "PermissionsTable",
    components: {
        Icon,
    },
    props: {
        'permissions': {
            type: Object,
        }
    },
    methods: {
        trans,
        roleTrans(type, role) {
            if (type === 'user_roles') {
                return trans('userroles.' + role);
            } else {
                return trans('tenant_roles.' + role);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    .table-active, .table-active > th, .table-active > td {
        & > th, & > td {
            background-color: var(--color-anthracite);
            color: white;
            font-family: var(--font-family-default);
            font-weight: 400;
        }
    }

    .table-bordered thead th, .table-bordered thead td {
        border-bottom-width: 1px;
    }

    .table-bordered > thead {
        position: sticky;
        top: 0;
        z-index: 1;
    }

    svg.icon {
        &.icon-check-circle {
            height: 22px;
            width: 22px;
            background-color: var(--color-primary);
            color: white;
            padding: 4px;
        }
    }

    tbody {
        background-color: white;
    }
</style>
