<template>
    <span
        :class="styleClasses"
        :title="tooltipText"
        v-html="labelCaption"
    />
</template>

<script>
    import {trans} from '@/Utility/Helpers';
    import moment from 'moment';
    import StatusLabelConfig from '@/Utility/StatusLabelConfig';

    export default {
        name: 'StatusLabel',
        props: {
            config: {
                type: StatusLabelConfig,
                default: new StatusLabelConfig(),
            }
        },
        computed: {

            /**
             * @returns {string}
             */
            styleClasses() {
                const classes = ['status-label'];

                // Convert type into a CSS class (e.g. "example_type123" -> "type-example-type123")
                if (this.config.type)
                {
                    classes.push('type-' + this.config.type.replace(/[^-a-z\d]/gi, '-').replace(/-{2,}|-$/g, ''));
                }

                if (this.config.icon)
                {
                    classes.push('has-icon');
                }

                return classes.join(' ');
            },

            /**
             * @returns {string}
             */
            labelCaption() {
                let title = null;

                // Use custom caption if given
                if (typeof this.config.caption === 'string')
                {
                    title = trans(`statuslabels.${this.config.caption}`, {}, false, false) || trans(this.config.caption, {}, false, false) || this.config.caption || null;
                }

                // Use translation of the type if one is available
                else if (typeof this.config.type === 'string') {
                    title = trans(`statuslabels.${this.config.type}`, {}, false, false) || null;
                }

                // Add date suffix if given
                if (this.config.date instanceof Date)
                {
                    title = (typeof title === 'string') ? `${title} &ndash; ` : '';
                    title = `${title}${moment(this.config.date).format('DD MMM YYYY')}`;
                }

                // Add icon if given
                if (typeof this.config.icon === 'string')
                {
                    if (this.config.icon.startsWith('icon_')) {
                        // SVG icon
                        title = `<svg class="icon"><use xlink:href="#${this.config.icon}"></use></svg>` + (title || '');
                    } else {
                        // image
                        title = `<img class="icon" src="${this.config.icon}" alt="">` + (title || '');
                    }
                }

                return title || '';
            },

            /**
             * @returns {string}
             */
            tooltipText() {
                return (typeof this.config.tooltip !== 'string')
                    ? null
                    : trans(`statuslabels.${this.config.tooltip}`)
                    || trans(`tooltips.${this.config.tooltip}`)
                    || trans(this.config.tooltip)
                    || this.config.tooltip
                    || null;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .status-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        height: 24px;
        padding: 6px 12px;
        color: var(--font-color-dark);
        background: var(--background-color-white);
        border: 1px solid var(--color-anthracite40);
        border-radius: 12px;
        font-family: var(--font-family-condensed-demibold);
        font-size: var(--font-size-smallest);
        line-height: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.type-policy {
            color: var(--font-color-light);
            background-color: var(--color-primary);
            border-color: var(--color-primary);
        }

        &.type-status {
            color: var(--font-color-light);
            background-color: var(--color-item-green-hover);
            border-color: var(--color-item-green-hover);
        }

        &.type-disabled {
            color: var(--font-color-light);
            background-color: var(--color-anthracite40);
            border-color: var(--color-anthracite40);
        }

        &.type-notification,
        &.type-warning {

            // Add a colored "dot" before the caption
            &:before {
                content: '';
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: var(--color-secondary);
            }

            &.has-icon:before {
                margin-right: 2px; // to counter the negative margin on the icon
            }
        }

        &.type-warning:before {
            background-color: var(--color-signal);
        }
    }
</style>

<!-- Unscoped (needed for the SVG icon) -->
<style lang="scss">
    .status-label svg.icon {
        flex-basis: 18px;
        width: 18px;
        height: 18px;
        margin-left: -2px;
    }
</style>
