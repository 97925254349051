<template>
    <tr :class="rowClasses">
        <td class="tenant-logo-cell">
            <img v-if="tenant.logo_thumbnail" class="logo" :src="tenant.logo_thumbnail" alt="" loading="lazy"/>
        </td>
        <td class="tenant-info-cell">
            <div class="tenant-info">
                <h2 class="tenant-info-title">
                    <Icon v-if="tenant.isAnyDefaultTenant" name="icon_admin"/>
                    {{ tenant.name }}
                </h2>
                <span
                    v-if="shouldShowTenantUid"
                    class="tenant-info-description">
                    {{ tenant.uid }}
                </span>
            </div>
        </td>
        <td class="tenant-separator-cell"></td>
        <td class="tenant-members text-center">
            <span class="cell-title">{{ trans('tenants.index.tenant_members') }}</span>
            <span class="cell-description">{{this.tenant.user_count}}</span>
        </td>
        <td class="tenant-separator-cell"></td>
        <td class="tenant-units text-center">
            <span class="cell-title">{{ trans('tenants.index.units') }}</span>
            <span class="cell-description">{{this.tenant.unit_count}}</span>
        </td>
        <td v-if="canSwitchTo || canUpdate"
            class="tenant-separator-cell">
        </td>
        <td v-if="canSwitchTo || canUpdate"
            class="tenant-actions">
            <ButtonSecondary
                v-if="canSwitchTo"
                caption="tenants.index.change_team_button"
                class="btn-impersonate-tenant"
                @trigger="onClickSwitchTenant"
            />
            <ButtonPrimary
                v-if="canUpdate"
                :href="$root.route('tenants.updateForm', {tenant: this.tenant.uid})"
                caption="tenants.index.settings_button"
                class="btn-edit-tenant"
                icon="icon_settings"
            />
        </td>
        <td v-if="canDelete" class="tenant-actions">
            <ButtonSecondary
                caption="tenants.index.delete_button"
                class="btn-delete-tenant btn-red"
                icon="icon_delete"
                @trigger="onClickDeleteTenant"
            />
        </td>
    </tr>
</template>

<script>

// Import classes:
import TenantWithDetails from '@/Models/Tenant/TenantWithDetails';
import {permission} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import ButtonPrimary from "@/Vue/Common/ButtonPrimary.vue";

export default {
        name: 'TenantListItem',
        components: {
            ButtonPrimary
        },

        emits: [
            'switch',
            'delete',
        ],

        props: {
            tenant: {
                type: TenantWithDetails,
                default: null
            },
        },

        computed: {
            rowClasses() {
                return this.isCurrentTenant ? 'current-tenant' : '';
            },

            canSwitchTo() {
                if (this.isCurrentTenant) {
                    return false;
                }
                return this.$gate.allows(Permission.ability(Permission.TenantsRead()), this.tenant) &&
                    this.$gate.allows(Permission.TenantsImpersonateRoleAdmin());
            },

            canUpdate() {
                return this.$gate.allows(Permission.ability(Permission.TenantsUpdate()), this.tenant);
            },

            canDelete() {
                return this.$gate.allows(Permission.ability(Permission.TenantsDelete()), this.tenant);
            },

            isCurrentTenant() {
                return this.tenant.uid === window.currentUser.tenant.uid;
            },

            shouldShowTenantUid() {
                return this.$gate.allows(Permission.TenantsDisplayUid());
            },
        },

        methods: {

            onClickSwitchTenant() {
                this.$emit('switch', this.tenant);
            },

            onClickDeleteTenant() {
                this.$emit('delete', this.tenant);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
