<template>
    <section class="provider-list-item" @dblclick="showProvider">

        <main class="provider-list-item-content">
            <img
                :alt="provider.driver.name"
                :src="provider.driver.logo"
                :title="provider.driver.name"
                class="logo"
            >
            <h3 class="name">
                {{ provider.name }}
            </h3>
            <a :href="`https://${provider.domain}`" class="domain" target="_blank">{{ provider.domain }}</a>
        </main>

        <footer>
            <div class="provider-list-item-actions">
                <!-- Btn: View -->
                <ButtonCircular
                    v-if="canRead"
                    v-tooltip="'buttons.identity_providers.show'"
                    :href="showRouteUrl"
                    icon="icon_arrow_right"
                />

                <!-- Btn: Delete -->
                <ButtonCircular
                    v-if="canDelete"
                    v-tooltip="'buttons.identity_providers.delete'"
                    icon="icon_delete"
                    @trigger="onTriggerDelete"
                />
            </div>

            <Icon class="icon-more" name="icon_more_horizontal" />
        </footer>
    </section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import IdentityProvider from '@/Models/IdentityProviders/IdentityProvider';
import {permission, route} from '@/Utility/Helpers';
import Icon from '@/Vue/Common/Icon.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({
    components: {
        ButtonCircular,
        Icon
    },

    props: {
        provider: {
            type: IdentityProvider,
            required: true,
        },
    },

    emits: [
        'delete-click'
    ],

    computed: {
        canRead() {
            return permission(Permission.IdentityProvidersRead());
        },

        canDelete() {
            return permission(Permission.IdentityProvidersDelete());
        },

        showRouteUrl() {
            return route('identity_providers.show', { identity_provider: this.provider.uid });
        },
    },

    methods: {
        showProvider() {
            if (!this.canRead) {
                return;
            }

            window.location.href = this.showRouteUrl;
        },

        onTriggerDelete() {
            this.$emit('delete-click', this.provider);
        },
    },
});
</script>

<style lang="css" scoped>
.provider-list-item {
    display: grid;
    grid-template-columns: auto 100px;
    grid-column-gap: 16px;

    background: var(--color-white);
    border-radius: var(--card-border-radius);
    padding: 16px;

    transition: box-shadow 0.15s ease-in-out;

    &:first-child {
        margin-top: 15px; /* To fix box-shadow being cut-off at the top */
    }

    &.selected,
    &:focus,
    &:focus-within,
    &:hover {
        box-shadow: var(--card-box-shadow-hover);

        footer .icon-more {
            display: none;
        }

        footer .provider-list-item-actions {
            display: flex;
        }
    }

    .provider-list-item-content {
        display: grid;
        grid-template-columns: max-content auto max-content;
        grid-template-rows: auto auto;
        grid-template-areas:
            'logo name icons'
            'logo domain icons';
        gap: 4px 16px;
        justify-items: start;

        .name {
            margin: 0;
            align-self: end;
        }

        .logo {
            width: 50px;
            grid-area: logo;
            align-self: center;
        }

        .domain {
            text-transform: none;
            grid-area: domain;
        }

        .domain::before {
            content: none;
        }
    }

    footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        &:has(.provider-list-item-actions:empty) {
            display: none;
        }

        .icon-more {
            flex-basis: 12px;
            margin-right: 14px;
            width: 40px;
            height: 40px;
        }

        .provider-list-item-actions {
            display: none;
            transition: opacity .1s ease;
        }
    }
}
</style>
